import config from '~/config'

import {
    ClientTokenResponse,
    LoginRequest,
    LoginResponse,
    PasswordRecoveryRequest,
    TeamMemberPasswordData,
    RefreshTokenRequest,
    SetPasswordRequest,
    SignUpRequest,
    UpdatePasswordResponse, ResendOtpResponse
} from './contracts'

import { del, form, post, RequestOptions } from '~/utils/ajax'
import { TeamMember } from '../team'
import message from '~/utils/message'

export * from './contracts'

const authRequestOptions: RequestOptions = {
    baseUrl: config.auth.baseUrl,
    anonymous: true
}

const apiRequestOptions: RequestOptions = {
    baseUrl: config.api.baseUrl,
    anonymous: true,
    setClientToken: true
}

export const authenticateClient = () =>
    post<ClientTokenResponse>(
        'oauth2/token',
        form({
            grant_type: 'client_credentials',
            scope: config.auth.client.scope,
            client_id: config.auth.client.id,
            client_secret: config.auth.client.secret
        }),
        authRequestOptions
    )

export const signUp = (data: SignUpRequest) =>
    post<void>('registration', data, apiRequestOptions)

export const setPassword = (data: SetPasswordRequest) =>
    post<void>('setpassword', data, apiRequestOptions)

export const setTeamMemberPassword = async (data: TeamMemberPasswordData) => {
    try {
        const authData = JSON.parse(localStorage.getItem('appAuth'))
        const response = await fetch(
            `${config.api.baseUrl}staff/update-password`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${authData.access_token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        )
        if (response.status !== 200) {
            const error = await response.json()
            throw error
        }
        return await response.json()
    } catch (error) {
        message.error(error)
    }
}

export const login = (request: LoginRequest) =>
    post<LoginResponse>(
        'oauth2/token',
        form({
            grant_type: 'password',
            scope: config.auth.user.scope,
            username: request.username,
            password: request.password,
            client_id: config.auth.client.id,
            client_secret: config.auth.client.secret
        }),
        authRequestOptions
    )

export const refreshToken = (request: RefreshTokenRequest) =>
    post<LoginResponse>(
        'oauth2/token',
        form({
            grant_type: 'refresh_token',
            scope: config.auth.user.scope,
            refresh_token: request.refresh_token,
            client_id: config.auth.client.id,
            client_secret: config.auth.client.secret
        }),
        authRequestOptions
    )

export const recoverPassword = (data: PasswordRecoveryRequest) =>
    post('staff/reset-password', data, apiRequestOptions)

export const signOut = () =>
    del<any>('connect/token', undefined, { baseUrl: config.auth.baseUrl })


export const sendOtp = (request: any) => post<ClientTokenResponse>('oauth2/token',
    form({
        grant_type: 'verification',
        verification_id: request.verifyId,
        otp: request.otp
    }),
    authRequestOptions
)

export const resendOtp = (request: any) => post<ResendOtpResponse>('oauth2/resend',
    form({
        grant_type: 'resend',
        verification_id: request.verifyId
    }),
    authRequestOptions
)