import React from 'react'

interface MaskEmailProps {
    email: string
}

export const MaskEmail = (email: string) => {

    const [localPart, domain] = email.split('@')
    if (localPart.length <= 4) {
        return `${localPart[0]}**${localPart[localPart.length - 1]}@${domain}`
    }
    const start = localPart.slice(0, 3)
    const end = localPart.slice(-2)
    const masked = start + '*'.repeat(localPart.length - 4) + end
    return `${masked}@${domain}`
}

export default MaskEmail