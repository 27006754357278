import page from 'page'

import qs from 'qs'

import actions from '~/actions'
import store from '~/app-store'

let path: string

page('*', (ctx, next) => {
    path = ctx.path
    ctx.query = qs.parse(ctx.querystring)
    next()
})

page('/', () => store.showDashboard())
page('/dashboard', () => store.showDashboard())

page('/signup', () => store.showSignUp())

page('/login', () => store.showLogin())

page('/set-password', ctx => store.showSetPassword(ctx.query.code, ctx.query.token, ctx.query.email, ctx.query.merid))

page('/password-recovery', ctx => store.showPasswordRecovery(ctx.query.password_expired))

page('/otp-verification', () => store.showOtpVerification())

page('/transactions/payments', () => store.showEpayPayments())

// p2p @Dos
page('/transactions/transfers', () => store.showP2PTransfers())

// oct @Dos
page('/transactions/oct-transactions', () => store.showOCTPayments())

// aft @Dos
page('/transactions/aft-transactions', () => store.showAFTPayments())

// cbc @Aza
// page('/transactions/cash-by-code', () => store.showCashByCode())

page('/payments/pos', () => store.showPosPayments())

page('/news', () => store.showNews())

page('/news-all', () => store.showAllNews())

page('/news/:newsId', context => store.showNewsSingle(context.params.newsId))

page('/settings/team', () => store.showEmployees())

page('/settings/configure-epay-template', () => store.showEpayTemplate())

page('/settings/terminal-settings', () => store.showTerminalSettings())

page('/profile', () => store.showUserProfile())

page('/statements/payment', () => store.showStatements())

page('/statements/payment/:statementId', context =>
    store.showStatement(context.params.statementId)
)

page('/statements/transfer', () => store.showStatementsTransfer())

page('/statements/transfer/:statementId', context =>
    store.showStatementTransfer(context.params.statementId)
)

page('/statements/oct', () => store.showStatementsOct())

page('/statements/oct/:statementId', context =>
    store.showStatementOct(context.params.statementId)
)

page('/statements/chargeback', () => store.showChargeback())

page('/under-construction', () => store.showUnderConstruction())

page('/payment-links', () => store.showPaymentLinks())

page('/banks-products/epay', () => store.showProducts())


page('/banks-products/credit', () => store.showCreditOrders())

page('/banks-products/epay/:requestId', context =>
    store.showProductRequest(context.params.productId)
)

page('/qr-codes/label-generator', () => store.showLabelGenerator())

page('/faq', () => store.showFaq())

page('/for-developers', () => store.showForDevelopers())

page('/documentation', () => store.showDocumentation())

page('/documentation/:nodeName', context => {
    return store.showDocumentationNode(context.params.nodeName)
})

page('/orders/current-orders', () => store.showCurrentOrders())

page('/orders/create-order', () => store.showCreateOrder())

page('/logs', () => store.showLogs())

page('/welcome-mobile', () => store.showWelcomeMobile())

page('/payment-settings', () => store.showPaymentSettings())

// page('/test', () => store.showTestPage())

// Old link redirects to new link:
page('/payments/epay', '/transactions/payments')
page('/transfer/p2p', '/transactions/transfers')
page('/payment-aft/aft', '/transactions/aft-transactions')
page('/transfer/oct', '/transactions/oct-transactions')

page('*', () => store.showUnderConstruction())

actions.getBackAction = () => {
    const to = path
    return () => page(to)
}

actions.openHome = () => page('/')

actions.openPayments = () => page('/transactions/payments')

actions.openLogin = () => page('/login')

actions.openProfile = () => page('/profile')

actions.openNews = () => page('/news')

actions.openNewsSingle = (newsId: string) => page(`/news/${newsId}`)

actions.openStatements = () => page('/statements/payment')

actions.openStatementPage = statementId => page(`/statements/payment/${statementId}`)

actions.openStatementsTransfer = () => page('/statements/transfer')

actions.openStatementTransferPage = statementId => page(`/statements/transfer/${statementId}`)

actions.openStatementsOct = () => page('/statements/oct')

actions.openStatementOctPage = statementId => page(`/statements/oct/${statementId}`)

actions.openPaymentLinks = () => page('/payment-links')

actions.openProducts = () => page('/banks-products/epay')

actions.openProductInquiry = id => page(`/banks-products/epay/${id}`)

actions.openProductsCredit = () => page('/banks-products/credit')

actions.openSettingsTemplate = () => page('/settings/configure-epay-template')

actions.openSettingsTerminal = () => page('/settings/terminal-settings')

actions.openSettingsEmployees = () => page('/settings/team')

actions.openDocumentation = () => page(`/documentation`)

actions.openOrderCreate = () => page(`/orders/create-order`)

actions.openCurrentOrder = () => page(`/orders/current-orders`)

actions.openExpiredPasswordRecovery = () => page(`/password-recovery?password_expired=1`)

actions.openPaymentSettings = () => page(`/payment-settings`)

actions.openDocumentationPage = (nodeName?: string) => {
    return page(`/documentation/${nodeName}`)
}

actions.openMobileWelcomePage = () => page('/welcome-mobile')

actions.openOtpVerification = () => page('/otp-verification')