import React, { useEffect, useRef } from 'react'
import { Col, Row, Tag, Skeleton } from 'antd'
import news from '~/assets/img/newsImg.png'
import styles from './styles.css'
import { RightOutlined } from '@ant-design/icons'
import moment from 'moment'


const constantTage = {
    system: { color: '#1890FF', text: 'Системное', name: 'system' },
    promotion: { color: '#1C9771', text: 'Акция', name: 'promotion' },
    NEW: { color: '#FFC700', text: 'Новое', name: 'NEW' }
}

const NewsCard = ({ isMobile, date, tags = [], image, title, text, period, link, loading = false }) => {
    const myRef = useRef(null)

    useEffect(() => {
        if (!isMobile) {
            myRef.current.innerHTML = text
        }

    }, [myRef])

    if (loading) {
        return (
            <Row className={styles.cardRow}>
                <Skeleton className={styles.skeleton} />
            </Row>
        )
    }
    return (
        <div className={styles.mobileWrapper} key={`${link}`}>
            <Row className={styles.cardRow} key={`${link}rows`}>
                <div>
                    {!isMobile && <span className={styles.date}>{moment.utc(date).format('HH:mm DD.MM.YYYY')}</span>}
                    {
                        tags.map(tag => tag &&
                            <Tag className={styles.tag} color={constantTage[tag]?.color} key={`${constantTage[tag]?.name}+ ${link}`}>
                                {constantTage[tag]?.text}
                            </Tag>
                        )
                    }
                </div>
                <div className={styles.separator}></div>
            </Row>
            <a href={`/news/${link}`} className={styles.cardRow}>
                <Col span={6}>
                    <img className={styles.cardImg} src={image ? image : news} />
                </Col>
                <Col span={16} className={styles.textSide}>
                    <Row>
                        <div className={styles.title}>{title}</div>
                        {!isMobile &&
                            <p className={styles.paragraph} ref={myRef}></p>
                        }
                    </Row>
                    <Row style={{ display: 'flex' }}>
                        {isMobile ?
                            <span className={styles.date}>{date}</span>
                            :
                            <span className={styles.period}>{period}</span>
                        }
                        <span className={styles.more}>Подробнее <RightOutlined className={styles.arrowIcon} color={'#747474'} /></span>
                    </Row>
                </Col>
            </a>
        </div>
    )
}

export default NewsCard