import antd from 'antd/lib/locale-provider/en_US'

import res from './res'

res.add('en', {
    unknownError: 'We cannot process your request at this time. Please, try again later',
    errorCodes: {
        75: 'An error occurred during registration. Please check that the data you entered is correct and try again.',
        506: 'The refund operation can be submitted the next day after transaction.',
        3141: 'Refunds are not possible due to lack of funds on the contract, replenish the contract or wait for new payments to be received.',
        3204: 'The OTP has expired',
        3205: 'The number of attempts to enter otp has expired',
        3206: 'Incorrect otp has been entered'
    },
    permissionError: 'You do not have an access to this operation. Please contact your administrator.',
    required: 'Required field',
    invalidEmail: 'Incorrect email format',
    invalidNumber: 'Incorrect number format',
    success: 'Success',
    info: 'Information',
    warn: 'Warning',
    error: 'Error',
    valueFrom: 'from',
    valueTo: 'to',
    errorCode: 'Error code',
    techinalInfo: 'Technical information',
    close: 'Close',
    paymentsSystems: {
        visa: 'Visa',
        masterCard: 'Mastercard',
        amexCard: 'American Express',
        other: 'Other cards'
    },
    longNumberDigits: {
        thousand: 'k',
        million: 'M',
        billion: 'G',
        trillion: 'T',
        quadrillion: 'P',
        quintillion: 'E'
    },
    antd,
    failedToFetch: 'Issue with the network connection or the request was blocked by a firewall or other security measure'
})
