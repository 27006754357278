import { action, computed, observable, runInAction } from 'mobx'
import { apiCheck, resendOtp, sendOtp } from '~/api'
import { getUserInfo, saveUserInfo } from '~/services/auth/auth-utils'
import appStore from '~/app-store'
import auth from '~/services/auth'
import { noThrow } from '~/utils/control-flow'
import { userError } from '~/utils/user-error'
import actions from '~/actions'

const DEFAULT_COOLDOWN_TIME = 59

export default class OtpVerificationStore {

    constructor() {
        this.checkVerification()
        this.startCooldown()
    }

    @observable
    public error: Error

    @computed
    public get errorMessage(): string {
        return userError(this.error).message
    }

    @observable public otp: string = ''
    @observable public cooldownTime: number = DEFAULT_COOLDOWN_TIME
    @observable public isSending = false
    @observable public isConfirmDisabled = true
    @observable public isResendDisabled = true

    
    @action.bound
    public startCooldown(): void {
        this.isResendDisabled = true
        this.cooldownTime = DEFAULT_COOLDOWN_TIME

        const interval = setInterval(() => {
            runInAction(() => {
                if (this.cooldownTime > 0) {
                    this.cooldownTime -= 1
                } else {
                    this.isResendDisabled = false
                    clearInterval(interval)
                }
            })
        }, 1000)
    }

    @action.bound
    public async resendOtp(): Promise<void> {
        const response = await noThrow(
            apiCheck(
                resendOtp({
                    verifyId: getUserInfo().verifyId
                })
            )
        )

        if (response.value) {
            await saveUserInfo({
                userLogin: getUserInfo().userLogin,
                verifyId: response.value.verifyId
            })
            this.startCooldown()
        }
    }


    @action.bound
    public onChangeOtp(event: React.ChangeEvent<HTMLInputElement>): void {
        const otpValue = event.target.value
        runInAction(() => {
            this.otp = otpValue
            this.isConfirmDisabled = otpValue.length !== 6
        })
    }

    @action.bound
    public async sendOtpCode(): Promise<void> {
        this.isSending = true

        try {
            const { value, error } = await noThrow(
                apiCheck(
                    sendOtp({
                        otp: this.otp,
                        verifyId: getUserInfo().verifyId
                    })
                )
            )
            if (error) {
                runInAction(() => {
                    this.error = error
                })
                return
            }

            const result = await auth.loginOtp(getUserInfo().userLogin, value)

            runInAction(() => {
                this.isSending = false
                if (!result.error) {
                    appStore.onLogin()
                } else {
                    this.error = result.error
                }
            })
        } catch (err) {
            runInAction(() => {
                this.isSending = false
                this.error = err
            })
        }


    }

    @action.bound
    private checkVerification(): void {
        if (!getUserInfo().verifyId) {
            window.location.href = '/'
        }
    }
}