import React from 'react'
import * as amplitude from 'amplitude-js'

import { action, computed, observable, reaction, runInAction, when } from 'mobx'

import uuid from 'uuid/v4'

import actions from '~/actions'

import { apiCheck, getProfile, getShops, Profile } from '~/api'

import { createOrGetMerchant } from '~/api/create-order'

import auth from '~/services/auth'

import AuthLayout from '~/layouts/auth'
import MainLayout from '~/layouts/main'
import LayoutStore from '~/layouts/main/layout-store'

import DictionaryStore from '~/dictionary/dictionary-store'

import Login from '~/pages/auth/login/login'
import LoginStore from '~/pages/auth/login/login-store'

import SignUp from '~/pages/auth/signup/signup'
import SignUpStore from '~/pages/auth/signup/signup-store'

import PasswordRecovery from '~/pages/auth/password-recovery/password-recovery'
import PasswordRecoveryStore from '~/pages/auth/password-recovery/password-recovery-store'

import SetPassword from '~/pages/auth/set-password/set-password'
import SetPasswordStore from '~/pages/auth/set-password/set-password-store'

import MainPage from '~/pages/activation/activation'

import Dashboard from '~/pages/dashboard/dashboard'
import DashboardStore from '~/pages/dashboard/dashboard-store'

import Team from '~/pages/settings/team/team'
import TeamStore from '~/pages/settings/team/team-store'

import EpayPayments from '~/pages/payments/epay/epay-payments'
import EpayPaymentsStore from '~/pages/payments/epay/epay-payments-store'

// p2p @Dos
import P2PTransfers from '~/pages/transfer/p2p/p2p-transfers'
import P2PTransfersStore from '~/pages/transfer/p2p/p2p-transfers-store'

// aft @Dos
import AFTPayments from '~/pages/payment-aft/aft/aft-payments'
import AFTPaymentsStore from '~/pages/payment-aft/aft/aft-payments-store'

// oct @Dos
import OCTPayments from '~/pages/payment-oct/oct/oct-payments'
import OCTPaymentsStore from '~/pages/payment-oct/oct/oct-payments-store'

import PosPaymentsStore from '~/pages/payments/pos/pos-payments-store'

import IdleTimerManager from '~/services/idle-timer/idle-timer-manager'

import IdleTimerComponent from '~/pages/auth/session-expiration/idle-timer-component'

import { UserProfile } from '~/pages/profile/user-profile'
import UserProfileStore from '~/pages/profile/user-profile-store'

import { log } from '~/utils'
import { noThrow } from '~/utils/control-flow'
import message from '~/utils/message'

import BrowserStore from '~/services/browser/browser-state'
import locale from '~/utils/locale'

import FaqStore from './pages/faq/faq-store'

import PaymentLinks from './pages/payment-links'
import PaymentLinksStore from './pages/payment-links/payment-links-store'
import Statements from './pages/statements/index'
import { StatementPage } from './pages/statements/statement'
import StatementsStore from './pages/statements/statements-store'
import UnderConstuction from './pages/under-construction/under-construction'

import ProductInquiryPage from './pages/products/product-Inquiry'
import ProductsStore from './pages/products/products-store'
import CreditProducts from '~/pages/products/credit/credit-products'
import CreditProductsStore from '~/pages/products/credit/credit-products-store'

import ForDevelopers from './pages/forDevelopers'
import ForDevelopersStore from './pages/forDevelopers/for-developers-store'

import DocumentationStore from './pages/documentation/documentation-store'

import DocumentationPage from '~/pages/documentation/documentation-page'

import { MenuLoader } from './layouts/main/main-menu-items'

import Logs from './pages/logs'
import EpayTemplate from '~/pages/settings/configure-epay-template/epay-template'
import EpayTemplateStore from '~/pages/settings/configure-epay-template/epay-template-store'
import TerminalSettingsStore from '~/pages/settings/terminal-settings/terminal-settings-store'
import TerminalSettingsPage from '~/pages/settings/terminal-settings/terminal-settings'
import CurrentOrdersStore from '~/pages/orders/current-orders/current-orders-store'
import CurrentOrders from '~/pages/orders/current-orders/current-orders'
import CreateOrder from '~/pages/orders/create-order/create-order'
import CreateOrderStore from '~/pages/orders/create-order/create-order-store'
import Faq from '~/pages/faq'
import InteractiveHintsStore from '~/components/interactive-hints/interactive-hints-store'
import { FrequencyType } from '~/api/statements'
import InteractiveHints from '~/components/interactive-hints'
import StatementsTransfer from './pages/statements-transfer/index'
import { StatementTransferPage } from './pages/statements-transfer/statement-transfer'
import StatementsTransferStore from './pages/statements-transfer/statements-transfer-store'
import StatementsOct from './pages/statements-oct/index'
import { StatementOctPage } from './pages/statements-oct/statement-oct'
import StatementsOctStore from './pages/statements-oct/statements-oct-store'
import WelcomeMobile from '~/pages/welcome-mobile'
import WelcomeMobileStore from '~/pages/welcome-mobile/welcome-mobile-store'
import WelcomePageLayout from '~/layouts/welcome'
import { mobileSectionsOrder } from '~/components/interactive-mobile-hints/constants'

import NewsSignle from './pages/news/news-single'
import NewsStore from './pages/news/news-store'
import NewsAll from './pages/news/news-all'
import NewsMain from './pages/news/news-main'
import PaymentSettings from './pages/payment-settings/payment-settings'
import InteractiveHint from '~/utils/interactive-hint'
import PaymentSettingsStore from '~/pages/payment-settings/payment-settings-store'
import CashByCodeStore from './pages/cash-by-code/cash-by-code/cash-by-code-store'
import CashByCode from './pages/cash-by-code/cash-by-code/cash-by-code'
import Chargeback from './pages/chargeback/chargeback'
import ChargebackStore from './pages/chargeback/chargeback-store'
import OtpVerificationStore from '~/pages/auth/otp-verification/otp-verification-store'
import OtpVerification from '~/pages/auth/otp-verification/otp-verification'
import { getAuth } from '~/services/auth/auth-utils'

export const THREAD_ID: string = new uuid()

class AppStore {
    private get dictionaryStore() {
        return (
            this._dictionaryStore ||
            (this._dictionaryStore = new DictionaryStore())
        )
    }

    constructor() {
        this.initialize()
        amplitude.getInstance().init('f3e158feb9925829425861d9318a1d6b')
    }

    @observable
    public ready: boolean = false

    @observable
    public showIdleWarning: boolean = false

    @observable
    public interactiveHintsVisible: boolean = false

    @observable
    public menu: MenuLoader

    @observable
    public menuData: any[]

    public profile: any

    @action.bound
    public onProlongSession() {
        this.showIdleWarning = false
        IdleTimerManager.getInstance().reset()
    }

    @action.bound
    public onIdleTimerOut() {
        this.showIdleWarning = true
    }

    @action
    public async onLogin() {
        await this.loadMenuData()

        const { value: shopList } = await noThrow(apiCheck(getShops()))

        if (!shopList) {
            return this.getRedirectPage()
        }

        const profile = await this.loadProfile()
        // @aza interactive-hints
        const interactiveFlags = await InteractiveHint.loadFlags(profile.merchantId)
        if (!BrowserStore.isMobile && interactiveFlags) {
            this.showInteractiveHints()
        }
        if (this.showMobileWelcomePage()) {
            return actions.openMobileWelcomePage()
        }

        this.redirectAfterLogin()
    }

    public async getRedirectPage() {
        const { value } = await noThrow(apiCheck(createOrGetMerchant()))

        if (value.statusName === 'INPROGRESS') {
            return actions.openOrderCreate()
        }
        return actions.openCurrentOrder()

    }

    @action
    public redirectAfterLogin() {
        const redirect = this._redirect
        if (!redirect) {
            return actions.openHome()
        }
        this._redirect = undefined
        redirect()
    }

    // hints @Aza
    @action
    public showInteractiveHints() {
        this._interactiveHintsDesktopStore = InteractiveHintsStore.immediatelyCall()
        this.interactiveHintsVisible = true
    }

    @action
    public showInteractiveHintsFromFAQ(sectionKeys: string[]) {
        this._interactiveHintsDesktopStore = InteractiveHintsStore.manuallyCall(sectionKeys)
        this.interactiveHintsVisible = true
    }

    @action
    public showInteractiveHintsFromPayments() {
        this._interactiveHintsDesktopStore = InteractiveHintsStore.afterFirstTransactionCall()
        this.interactiveHintsVisible = true
    }

    @action.bound
    public hideInteractiveHints() {
        this.interactiveHintsVisible = false
        this._interactiveHintsDesktopStore = undefined
    }

    @computed
    public get interactiveHints() {
        if (!this.interactiveHintsVisible) {
            return null
        }
        return (
            <InteractiveHints
                store={this._interactiveHintsDesktopStore}
                layoutStore={this._layoutStore}
                appStore={this}
            />
        )
    }

    @observable
    public layout: (props: { children: any }) => JSX.Element = () => null

    @observable
    public page: () => JSX.Element = () => null

    @action
    public showOtpVerification() {
        const store = new OtpVerificationStore()

        this.setPage(() => <OtpVerification store={store} />, this.authLayout)
    }

    @action
    public showLogin() {
        const store = new LoginStore()

        this.setPage(() => <Login store={store} />, this.authLayout)
    }

    @action
    public showSignUp() {
        const store = new SignUpStore()

        this.setPage(() => <SignUp store={store} />, this.authLayout)
    }

    @action
    public showPasswordRecovery(passwordExpired?: string) {
        const store = new PasswordRecoveryStore(passwordExpired)

        this.setPage(() => <PasswordRecovery store={store} />, this.authLayout)
    }

    @action
    public showSetPassword(
        code?: string,
        token?: string,
        email?: string,
        merid?: string
    ) {
        const store = new SetPasswordStore(code, token, email, merid)

        this.setPage(() => <SetPassword store={store} />, this.authLayout)
    }

    @action
    public showFaq() {
        this.authorize(() => {
            const store = this._faqStore || (this._faqStore = new FaqStore())

            this.setPage(() => <Faq store={store} appStore={this} />)

            // this.setPage(() => <UnderConstuction />)
        })
    }

    @action
    public showNews() {
        this.authorize(() => {
            const store =
                this._newsStore ||
                (this._newsStore = new NewsStore())
            this.setPage(() => <NewsMain store={store} appStore={this} />)
        })
    }

    public showAllNews() {
        this.authorize(() => {
            const store =
                this._newsStore ||
                (this._newsStore = new NewsStore())
            this.setPage(() => <NewsAll store={store} appStore={this} />)
        })
    }

    @action
    public showNewsSingle(newsId: string) {
        this.authorize(() => {
            if (this._newsStore) {
                this._newsStore.setNewsId(newsId)
            }

            const store =
                this._newsStore ||
                (this._newsStore = new NewsStore(newsId))

            this.setPage(() => <NewsSignle store={store} appStore={this} newsId={newsId} />)
        })
    }

    @action
    public showForDevelopers() {
        this.authorize(() => {
            const store =
                this._forDevelopersStore ||
                (this._forDevelopersStore = new ForDevelopersStore())

            this.setPage(() => <ForDevelopers store={store} />)
        })
    }

    @action
    public showDocumentation(documentNodeName?: string) {
        if (this._documentationStore) {
            this._documentationStore.setDocumentNode(documentNodeName)
        }

        const store =
            this._documentationStore ||
            (this._documentationStore = new DocumentationStore(
                documentNodeName
            ))

        this.setPage(() => <DocumentationPage store={store} />)
    }

    @action
    public showDocumentationNode(documentNodeName?: string) {
        if (this._documentationStore) {
            this._documentationStore.setDocumentNode(documentNodeName)
        }

        const store =
            this._documentationStore ||
            (this._documentationStore = new DocumentationStore(
                documentNodeName
            ))

        this.setPage(() => <DocumentationPage store={store} />)
    }

    @action
    public showHome() {
        this.authorize(() => this.setPage(() => <MainPage />))
    }

    @action
    public showLogs() {
        this.setPage(() => <Logs />)
    }

    @action
    public showUnderConstruction() {
        this.authorize(() => this.setPage(() => <UnderConstuction />))
    }

    @action
    public showUserProfile() {
        const store = new UserProfileStore()

        this.authorize(() => this.setPage(() => <UserProfile store={store} />))
    }

    @action
    public showEmployees() {
        this.authorize(() => {
            const store = this._teamStore || (this._teamStore = new TeamStore())

            this.setPage(() => <Team store={store} />)

            // this.setPage(() => <UnderConstuction />)
        })
    }

    @action
    public showEpayTemplate() {
        this.authorize(() => {
            const store =
                this._epayTemplateStore ||
                (this._epayTemplateStore = new EpayTemplateStore())

            this.setPage(() => <EpayTemplate store={store} />)
        })
    }

    @action
    public showTerminalSettings() {
        this.authorize(() => {
            const store = this._terminalSettingsStore ||
                (this._terminalSettingsStore = new TerminalSettingsStore())

            this.setPage(() => <TerminalSettingsPage store={store} />)
        })
    }

    @action
    public showDashboard() {
        this.authorize(() => {
            const store =
                this._dashboardStore ||
                (this._dashboardStore = new DashboardStore())

            this.setPage(() => <Dashboard store={store} />)
        })
    }

    @action
    public showEpayPayments() {
        this.authorize(() => {
            const store =
                this._epayPaymentsStore ||
                (this._epayPaymentsStore = new EpayPaymentsStore())

            this.setPage(() => (
                <EpayPayments store={store}
                    dictionary={this.dictionaryStore}
                    appStore={this} />
            ))
        })
    }

    // p2p @Dos
    @action
    public showP2PTransfers() {
        this.authorize(() => {
            const store =
                this._P2PTransfersStore ||
                (this._P2PTransfersStore = new P2PTransfersStore())

            this.setPage(() => (
                <P2PTransfers store={store} dictionary={this.dictionaryStore} />
            ))
        })
    }

    // aft @Dos
    @action
    public showAFTPayments() {
        this.authorize(() => {
            const store =
                this._AFTPaymentsStore ||
                (this._AFTPaymentsStore = new AFTPaymentsStore())

            this.setPage(() => (
                <AFTPayments store={store} dictionary={this.dictionaryStore} />
            ))
        })
    }

    // oct @Dos
    @action
    public showOCTPayments() {
        this.authorize(() => {
            const store =
                this._OCTPaymentsStore ||
                (this._OCTPaymentsStore = new OCTPaymentsStore())

            this.setPage(() => (
                <OCTPayments store={store} dictionary={this.dictionaryStore} />
            ))
        })
    }

    // cashByCode @Aza
    @action
    public showCashByCode() {
        this.authorize(() => {
            const store =
                this._CashByCodeStore ||
                (this._CashByCodeStore = new CashByCodeStore())

            this.setPage(() => (
                <CashByCode store={store} />
            ))
        })
    }

    @action
    public async showCurrentOrders() {

        const hasStore = await this.hasStore()

        if (hasStore) {
            return actions.openHome()
        }

        this.authorize(() => {
            const store =
                this._currentOrdersStore ||
                (this._currentOrdersStore = new CurrentOrdersStore())

            this.setPage(() => (
                <CurrentOrders store={store} />
            ))
        })
    }

    @action
    public showPaymentSettings() {
        this.authorize(() => {
            const store =
                this._paymentSettingsStore ||
                (this._paymentSettingsStore = new PaymentSettingsStore())

            this.setPage(() => (
                <PaymentSettings store={store} />
            ))
        })
    }

    @action
    public async showCreateOrder() {

        const hasStore = await this.hasStore()

        if (hasStore) {
            return actions.openHome()
        }

        this.authorize(() => {
            const store =
                this._createOrderStore ||
                (this._createOrderStore = new CreateOrderStore())

            this.setPage(() => (
                <CreateOrder store={store} />
            ))
        })
    }

    public async hasStore() {
        const shopList = await noThrow(apiCheck(getShops()))
        return !!shopList.value
    }

    @action
    public showPosPayments() {
        this.authorize(() => {
            // const store =
            //     this._posPaymentsStore ||
            //     (this._posPaymentsStore = new PosPaymentsStore())

            // this.setPage(() => <PosPayments store={store} dictionary={this.dictionaryStore} />)
            this.setPage(() => <UnderConstuction />)
        })
    }

    @action
    public showStatements() {
        this.authorize(() => {
            const store =
                this._statementsStore ||
                (this._statementsStore = new StatementsStore())

            this.setPage(() => <Statements store={store} />)
        })
    }

    @action
    public showStatementsTransfer() {
        this.authorize(() => {
            const store =
                this._statementsTransferStore ||
                (this._statementsTransferStore = new StatementsTransferStore())

            this.setPage(() => <StatementsTransfer store={store} />)
        })
    }

    @action
    public showStatementsOct() {
        this.authorize(() => {
            const store =
                this._statementsOctStore ||
                (this._statementsOctStore = new StatementsOctStore())

            this.setPage(() => <StatementsOct store={store} />)
        })
    }

    @action
    public showProducts() {
        this.authorize(() => {
            // const store =
            //     this._productsStore ||
            //     (this._productsStore = new ProductsStore())

            // this.setPage(() => <ProductsPage store={store} />)

            this.setPage(() => <UnderConstuction />)
        })
    }

    @action
    public showCreditOrders() {
        this.authorize(() => {
            const store =
                this._creditProductsStore ||
                (this._creditProductsStore = new CreditProductsStore())

            this.setPage(() => <CreditProducts store={store} />)
        })
    }

    @action
    public showProductRequest(statementId?: string) {
        this.authorize(() => {
            const store =
                this._productsStore ||
                (this._productsStore = new ProductsStore())

            this.setPage(() => <ProductInquiryPage store={store} />)
        })
    }

    @action
    public showLabelGenerator() {
        this.authorize(() => {
            // const store = this._productsStore || (this._productsStore = new ProductsStore())

            this.setPage(() => <UnderConstuction />)
        })
    }

    @action
    public showStatement(statementId: string) {
        this.authorize(() => {
            if (this._statementsStore) {
                this._statementsStore.setStatement(statementId)
            }

            const store =
                this._statementsStore ||
                (this._statementsStore = new StatementsStore(statementId))

            this.setPage(() => <StatementPage store={store} />)
        })
    }

    @action
    public showStatementTransfer(statementId: string) {
        this.authorize(() => {
            if (this._statementsTransferStore) {
                this._statementsTransferStore.setStatement(statementId)
            }

            const store =
                this._statementsTransferStore ||
                (this._statementsTransferStore = new StatementsTransferStore(statementId))

            this.setPage(() => <StatementTransferPage store={store} />)
        })
    }

    @action
    public showStatementOct(statementId: string) {
        this.authorize(() => {
            if (this._statementsOctStore) {
                this._statementsOctStore.setStatement(statementId)
            }

            const store =
                this._statementsOctStore ||
                (this._statementsOctStore = new StatementsOctStore(statementId))

            this.setPage(() => <StatementOctPage store={store} />)
        })
    }

    @action
    public showPaymentLinks() {
        this.authorize(() => {
            const store =
                this._paymentLinksStore ||
                (this._paymentLinksStore = new PaymentLinksStore())

            this.setPage(() => (
                <PaymentLinks store={store} dictionary={this.dictionaryStore} />
            ))
        })
    }

    @action
    public showWelcomeMobile() {
        if (this.showMobileWelcomePage()) {
            this.authorize(() => {
                const store =
                    this._welcomeMobileStore ||
                    (this._welcomeMobileStore = new WelcomeMobileStore())

                this.setPage(() => <WelcomeMobile store={store} appStore={this} />, this.welcomePageLayout)
            })
        } else {
            this.redirectAfterLogin()
        }
    }

    @action
    public showChargeback() {
        this.authorize(() => {
            const store =
                this._chargebackStore ||
                (this._chargebackStore = new ChargebackStore())

            this.setPage(() => <Chargeback store={store} />)
        })
    }

    public async loadMenuData() {
        const menuLoader = new MenuLoader()
        await menuLoader.loadData()
        runInAction(() => (this.menu = menuLoader))
        runInAction(() => (this.menuData = menuLoader.menuData))
    }

    // @Aza functions for interactive hints
    @action
    public openPaymentsColumnControlModal() {
        this._epayPaymentsStore.openCustomizeGridForm()
    }

    @action
    public closePaymentsColumnControlModal() {
        this._epayPaymentsStore.closeCustomizeGridForm()
    }

    @action
    public openDetailedPayment() {
        if (!!this._epayPaymentsStore.successfulPayment) {
            this._epayPaymentsStore.openPaymentDetailedForm(this._epayPaymentsStore.successfulPayment)
        }
    }

    @action
    public closeDetailedPayment() {
        this._epayPaymentsStore.closePaymentDetailedForm()
    }

    @action
    public openPaymentActions() {
        this._epayPaymentsStore.openPaymentActions()
    }

    @action
    public closePaymentActions() {
        this._epayPaymentsStore.closePaymentActions()
    }

    @action
    public openPaymentSearchFilter() {
        this._epayPaymentsStore.openFilterSearchInput()
    }

    @action
    public closePaymentSearchFilter() {
        this._epayPaymentsStore.closeFilterSearchInput()
    }

    @action
    public async getSuccessfulEpayPayment(): Promise<boolean> {
        if (this._epayPaymentsStore.data?.length && !this._epayPaymentsStore.paymentsLoading) {
            return !!this._epayPaymentsStore.successfulPayment
        }
        await when(() => !!this._epayPaymentsStore.successfulPayment || !this._epayPaymentsStore.paymentsLoading)
        return !!this._epayPaymentsStore.successfulPayment
    }

    @action
    public selectSuccessfulPayment() {
        if (!!this._epayPaymentsStore.successfulPayment) {
            const successfulPaymentId = this._epayPaymentsStore.successfulPayment.id
            this._epayPaymentsStore.setSelectedPayments([successfulPaymentId])
        }
    }

    @action
    public deselectSuccessfulPayment() {
        this._epayPaymentsStore.setSelectedPayments([])
    }

    @action
    public openTeamInvitationModal() {
        this._teamStore.openInvitationModal()
    }

    @action
    public closeTeamInvitationModal() {
        this._teamStore.toggleInvitationModal()
    }

    @action
    public toggleCreditProductsModal() {
        this._creditProductsStore.toggleOrderConfigForm()
    }

    @action
    public togglePaymentLinksModal() {
        this._paymentLinksStore.togglePaymentLinkConfigForm()
    }

    @action
    public openFirstStatement() {
        const { 0: record } = this._statementsStore.statements
        if (record) {
            this._statementsStore.openStatementDetail(record)
        }
    }

    @action
    public toggleStatementModal() {
        this._statementsStore.toggleStatementConfigForm()
    }

    @action
    public toggleStatementConfigForm() {
        this._statementsStore.toggleStatementConfigForm(FrequencyType.daily)
    }

    @action
    public openFirstStatementTransfer() {
        const { 0: record } = this._statementsTransferStore.statements
        if (record) {
            this._statementsTransferStore.openStatementDetail(record)
        }
    }

    @action
    public openFirstStatementOct() {
        const { 0: record } = this._statementsOctStore.statements
        if (record) {
            this._statementsOctStore.openStatementDetail(record)
        }
    }

    @action
    public toggleStatementTransferModal() {
        this._statementsTransferStore.toggleStatementConfigForm()
    }

    @action
    public toggleStatementOctModal() {
        this._statementsOctStore.toggleStatementConfigForm()
    }

    @action
    public toggleStatementTransferConfigForm() {
        this._statementsTransferStore.toggleStatementConfigForm(FrequencyType.daily)
    }

    @action
    public toggleStatementOctConfigForm() {
        this._statementsOctStore.toggleStatementConfigForm(FrequencyType.daily)
    }

    // @Aza end of functions for interactive hints

    // @action
    // public showTestPage() {
    //     const store = new TestPageStore()
    //     this.setPage(() => <TestPage store={store} />)
    // }

    private async initialize() {
        when(
            () => auth.initialized,

            async () => {
                if (auth.isAuthenticated) {
                    const profile = await this.loadProfile()
                    // @aza interactive-hints
                    const interactiveFlags = await InteractiveHint.loadFlags(profile.merchantId)
                    if (!BrowserStore.isMobile && interactiveFlags) {
                        this.showInteractiveHints()
                    }
                    runInAction(() => (
                        this.ready = true,
                        this.profile = profile
                    ))
                } else {
                    this.ready = true
                }

                reaction(
                    () => auth.isAuthenticated,
                    isAuthenticated => {
                        if (!isAuthenticated) {
                            this.clearCache()
                            this.redirectToLogin()
                        }
                    }
                )
            }
        )
        await this.loadMenuData()
    }

    private async loadProfile() {
        const result = await noThrow(apiCheck(getProfile()))
        localStorage.removeItem('role')
        if (result.error) {
            log('failed to load user profile')
        } else if (result.value && result.value.lang) {
            locale.setLang(result.value.lang)
            localStorage.setItem('role', result.value.role)
            return result.value
        } else {
            log('invalid user profile data')
        }
        return undefined
    }

    private showMobileWelcomePage(): boolean {
        if (InteractiveHint.flags && BrowserStore.isMobile) {
            const trueFlags = mobileSectionsOrder.filter((key) => InteractiveHint.flags[key])
            return trueFlags.length === mobileSectionsOrder.length
        }
        return false
    }

    private authorize(route: () => void) {
        if (auth.isAuthenticated) return route()

        this.redirectToLogin()
    }

    private redirectToLogin() {
        this._redirect = actions.getBackAction()

        actions.openLogin()
    }

    @action
    private setPage(page: () => JSX.Element, layout?: any) {
        this.layout = layout || this.appLayout
        if (BrowserStore.isMobile) {
            this._layoutStore.toggleSider(false)
        }
        message.clearAll()
        this.page = page
    }

    private authLayout = ({ children }: { children?: React.ReactNode }) => (
        <AuthLayout>{children}</AuthLayout>
    )

    private appLayout = ({ children }: { children?: React.ReactNode }) => (
        <MainLayout appStore={this} layoutStore={this._layoutStore}>
            {children}
            {auth.isAuthenticated && (
                <IdleTimerComponent
                    visible={auth.showIdleWarning}
                    onOK={auth.onProlongSession}
                    onCancel={auth.logout}
                />
            )}
        </MainLayout>
    )

    private welcomePageLayout = ({ children }: { children?: React.ReactNode }) => (
        <WelcomePageLayout>
            {children}
            {auth.isAuthenticated && (
                <IdleTimerComponent
                    visible={auth.showIdleWarning}
                    onOK={auth.onProlongSession}
                    onCancel={auth.logout}
                />
            )}
        </WelcomePageLayout>
    )

    private clearCache() {
        this._epayPaymentsStore = undefined
        // p2p @Dos
        this._P2PTransfersStore = undefined
        // aft @Dos
        this._AFTPaymentsStore = undefined

        // oct @Dos
        this._OCTPaymentsStore = undefined

        this._posPaymentsStore = undefined
        this._teamStore = undefined
        this._dictionaryStore = undefined
        this._statementsStore = undefined
        this._statementsTransferStore = undefined
        this._statementsOctStore = undefined
        this._currentOrdersStore = undefined
        this._createOrderStore = undefined
        this._welcomeMobileStore = undefined
        this._newsStore = undefined
        this.hideInteractiveHints()
    }

    private _redirect: () => void

    private _layoutStore = LayoutStore

    private _interactiveHintsDesktopStore: InteractiveHintsStore

    private _welcomeMobileStore: WelcomeMobileStore

    private _dictionaryStore: DictionaryStore

    private _epayPaymentsStore: EpayPaymentsStore

    // p2p @Dos
    private _P2PTransfersStore: P2PTransfersStore

    // aft @Dos
    private _AFTPaymentsStore: AFTPaymentsStore

    // oct @Dos
    private _OCTPaymentsStore: OCTPaymentsStore

    // cashByCode @Aza
    private _CashByCodeStore: CashByCodeStore

    private _posPaymentsStore: PosPaymentsStore

    private _statementsStore: StatementsStore

    private _statementsTransferStore: StatementsTransferStore

    private _statementsOctStore: StatementsOctStore

    private _productsStore: ProductsStore

    private _creditProductsStore: CreditProductsStore

    private _paymentLinksStore: PaymentLinksStore

    private _teamStore: TeamStore

    private _epayTemplateStore: EpayTemplateStore

    private _terminalSettingsStore: TerminalSettingsStore

    private _dashboardStore: DashboardStore

    private _faqStore: FaqStore

    private _newsStore: NewsStore

    private _forDevelopersStore: ForDevelopersStore

    private _documentationStore: DocumentationStore

    private _currentOrdersStore: CurrentOrdersStore

    private _createOrderStore: CreateOrderStore

    private _paymentSettingsStore: PaymentSettingsStore

    private _chargebackStore: ChargebackStore
}

export default new AppStore()
