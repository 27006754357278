import { action, computed, observable, runInAction } from 'mobx'

import appStore from '~/app-store'
import auth from '~/services/auth'
import { isPasswordExpired, saveUserInfo } from '~/services/auth/auth-utils'
import FieldStore, { isValidArr } from '~/utils/field-store'
import { userError } from '~/utils/user-error'
import { email, required } from '~/utils/validation'

import res from '../res'
import actions from '~/actions'
import { apiCheck } from '~/api'
import { login } from '~/api/auth'

export default class LoginStore {
    public email = new FieldStore<string>([
        required(() => res().emailRequired),
        email
    ])

    public password = new FieldStore<string>([
        required(() => res().passwordRequired)
    ])

    @observable
    public isPasswordShown = false

    @observable
    public isSending = false

    @observable
    public error: Error

    @computed
    public get errorMessage(): string {
        return userError(this.error).message
    }

    @action
    public async login() {
        if (!isValidArr(this.email, this.password)) return

        this.isSending = true

        try {
            const response = await apiCheck(
                login({
                    username: this.email.value,
                    password: this.password.value
                })
            )

            if (response.verifyId) {
                await saveUserInfo({
                    userLogin: this.email.value,
                    verifyId: response.verifyId
                })

                return actions.openOtpVerification()

            } else {
                const result = await auth.login(
                    {
                        username: this.email.value,
                        password: this.password.value
                    },
                    response
                )

                if (!result.error) {
                    await appStore.onLogin()
                }

                runInAction(() => {
                    if (!result.error) return
                    this.isSending = false
                    this.error = result.error
                    if (isPasswordExpired(result.error)) {
                        actions.openExpiredPasswordRecovery()
                    }
                })
            }
        } catch (err) {
            runInAction(() => {
                this.isSending = false
                this.error = err
            })
        }
    }

    @action
    public togglePasswordVisibility() {
        this.isPasswordShown = !this.isPasswordShown
    }
}
