import React, { FormEvent } from 'react'

import { observer } from 'mobx-react'
import { Button, Form, FormItem, Input } from '~/components'
import styles from '~/layouts/auth/styles.css'
import res from '../res'
import OtpVerificationStore from './otp-verification-store'
import { getUserInfo } from '~/services/auth/auth-utils'
import maskEmail from '~/components/mask-email'


export default observer(({ store }: { store: OtpVerificationStore }) => {
    function submit(e: FormEvent) {
        store.sendOtpCode()
        e.preventDefault()
    }

    const { userLogin } = getUserInfo()

    return (
        <div>
            {getUserInfo().verifyId &&
                <div data-semantic-id="login_form">
                    <div className={styles.header}>
                <span data-semantic-id="login_title" className={styles.title}>
                    {res().loginTitle}
                </span>
                    </div>
                    <div className={styles.main}>

                        <div className={styles.header}>
                            <div data-semantic-id="login_title" className={styles.title}>
                                {res().confirmTitle}
                            </div>
                            <div className={styles.confirmDesc}>
                                {res().emailConfirmation} {maskEmail(userLogin)}
                            </div>
                        </div>
                        <Form onSubmit={submit}>
                            <div className={styles.otp}>
                                <Form.Item>
                                    <Input
                                        className={styles.otpField}
                                        onChange={store.onChangeOtp}
                                        placeholder={res().otpConfirmPlaceholder}
                                        maxLength={6}
                                        required
                                    />
                                </Form.Item>
                            </div>
                            {store.error && (
                                <div className={styles.error}>{store.errorMessage}</div>
                            )}
                            <FormItem className={styles.buttons}>
                                <Button
                                    onClick={store.resendOtp}
                                    disabled={store.isResendDisabled}
                                    type="primary"
                                    htmlType="button"
                                    size="large"
                                    className={`${styles.mainButton} ${styles.resendButton}`}
                                >
                                    {store.cooldownTime ? `${res().resendTime} 0:${store.cooldownTime}` : res().resend}

                                </Button>

                                <Button
                                    data-semantic-id="confirm_button"
                                    disabled={store.isConfirmDisabled}
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className={styles.mainButton}
                                >
                                    {res().confirmAction}

                                </Button>
                            </FormItem>
                            <div className={styles.secureText}> {res().secureText} </div>
                        </Form>

                    </div>
                </div>
            }
        </div>

    )
})
